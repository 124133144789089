import type { Address } from '@commercetools/frontend-domain-types/account/Address';
import type { ReCaptchaVerifyResponse, ReCaptchaErrors, ReCaptchaBackendSettings } from '@wilm/shared-types/google/reCaptcha';
import type { DelegatesData } from '@wilm/shared-types/cart/Cart';
import { FieldValidationRules } from '@wilm/shared-types/validation-rules/common';
import type { AccountExtended, AccountChangePassword } from '../interfaces/AccountExtended';
import type {
    FieldErrors,
    AccountFieldErrors,
    ChangePasswordFieldErrors,
    DelegatesFieldErrors,
    ValidationErrors
} from './interfaces/FieldErrors';
import type { ValidationMapping } from './interfaces/ValidationMapping';
import { validationRules } from './rules';
import { isEmpty, isNotEmpty } from '../utils/ValuesHelper';

export const handleValidationMapping = () => {
    const validationMapping: ValidationMapping = {
        streetName: {
            regex: FieldValidationRules.ADDRESS.REGEX,
            minLength: FieldValidationRules.ADDRESS.MIN,
            maxLength: FieldValidationRules.ADDRESS.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.address',
                range: 'error.range.address',
                regex: 'error.regex.address'
            }
        },
        additionalAddressInfo: {
            regex: FieldValidationRules.ADDRESS.REGEX,
            minLength: FieldValidationRules.ADDRESS.MIN,
            maxLength: FieldValidationRules.ADDRESS.MAX,
            mandatory: false,
            errorMessages: {
                range: 'error.range.additional.info',
                regex: 'error.regex.address'
            }
        },
        city: {
            regex: FieldValidationRules.CITY.REGEX,
            minLength: FieldValidationRules.CITY.MIN,
            maxLength: FieldValidationRules.CITY.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.city',
                range: 'error.range.city',
                regex: 'error.validation.city'
            }
        },
        postalCode: {
            regex: FieldValidationRules.POSTAL.REGEX,
            minLength: FieldValidationRules.POSTAL.MIN,
            maxLength: FieldValidationRules.POSTAL.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.postalCode',
                range: 'error.range.postalCode',
                regex: 'error.validation.postalCode'
            }
        },
        country: {
            minLength: FieldValidationRules.COUNTRY.MIN,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.country',
                range: 'error.missing.country'
            }
        },

        region: {
            mandatory: false,
            mandatoryIf: {
                key: 'country',
                value: ['US', 'CA']
            },
            errorMessages: {
                validation: 'error.missing.state'
            }
        },
        phone: {
            regex: FieldValidationRules.PHONE.REGEX,
            minLength: FieldValidationRules.PHONE.MIN,
            maxLength: FieldValidationRules.PHONE.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.phoneNumber',
                range: 'error.missing.phoneNumber'
            }
        },
        firstName: {
            regex: FieldValidationRules.NAME.REGEX,
            minLength: FieldValidationRules.NAME.MIN,
            maxLength: FieldValidationRules.NAME.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.first.name',
                range: 'error.range.first.name',
                regex: 'error.validation.name'
            }
        },
        lastName: {
            regex: FieldValidationRules.NAME.REGEX,
            minLength: FieldValidationRules.NAME.MIN,
            maxLength: FieldValidationRules.NAME.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.last.name',
                range: 'error.range.last.name',
                regex: 'error.validation.name'
            }
        }
    } as const;

    return validationMapping;
};

const handleValidationAccountMapping = () => {
    const validationAccountMapping: ValidationMapping = {
        companyName: {
            minLength: FieldValidationRules.TRADING_NAME.MIN,
            maxLength: FieldValidationRules.TRADING_NAME.MAX,
            mandatory: false,
            errorMessages: {
                validation: 'error.missing.company.name',
                range: 'error.range.company.name'
            },
            mandatoryIf: {
                key: 'isB2B',
                value: [true]
            }
        },

        accountPhone: {
            regex: FieldValidationRules.PHONE.REGEX,
            minLength: FieldValidationRules.PHONE.MIN,
            maxLength: FieldValidationRules.PHONE.MAX,
            mandatory: false,
            errorMessages: {
                validation: 'error.missing.accountPhone',
                range: 'error.missing.accountPhone'
            },
            mandatoryIf: {
                key: 'isB2B',
                value: [true]
            }
        },

        email: {
            regex: FieldValidationRules.EMAIL.REGEX,
            minLength: FieldValidationRules.EMAIL.MIN,
            maxLength: FieldValidationRules.EMAIL.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.email',
                range: 'error.range.email',
                regex: 'error.validation.email'
            }
        },
        password: {
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.password'
            },
            mandatoryIf: {
                key: 'isExisting',
                value: [false]
            }
        },
        confirmPassword: {
            mandatory: true,
            errorMessages: {
                validation: 'error.confirm.password'
            },
            mandatoryIf: {
                key: 'isExisting',
                value: [false]
            },
            sameAs: 'password'
        },
        salesforceFinanceContact: {
            regex: FieldValidationRules.EMAIL.REGEX,
            minLength: FieldValidationRules.EMAIL.MIN,
            maxLength: FieldValidationRules.EMAIL.MAX,
            mandatory: false,
            errorMessages: {
                validation: 'error.missing.email',
                range: 'error.range.email',
                regex: 'error.validation.email'
            },
            mandatoryIf: {
                key: 'isB2B',
                value: [true]
            }
        },
        companyAddress1: {
            regex: FieldValidationRules.ADDRESS.REGEX,
            minLength: FieldValidationRules.ADDRESS.MIN,
            maxLength: FieldValidationRules.ADDRESS.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.company.address1',
                range: 'error.range.company.address1',
                regex: 'error.regex.address'
            },
            mandatoryIf: {
                key: 'isB2B',
                value: [true]
            }
        },
        companyAddress2: {
            regex: FieldValidationRules.ADDRESS.REGEX,
            minLength: FieldValidationRules.ADDRESS.MIN,
            maxLength: FieldValidationRules.ADDRESS.MAX,
            mandatory: false,
            errorMessages: {
                range: 'error.range.company.address2',
                regex: 'error.regex.address'
            }
        },
        companyAddress3: {
            regex: FieldValidationRules.ADDRESS.REGEX,
            minLength: FieldValidationRules.ADDRESS.MIN,
            maxLength: FieldValidationRules.ADDRESS.MAX,
            mandatory: false,
            errorMessages: {
                range: 'error.range.company.address3'
            }
        },
        companyCity: {
            regex: FieldValidationRules.CITY.REGEX,
            minLength: FieldValidationRules.CITY.MIN,
            maxLength: FieldValidationRules.CITY.MAX,
            mandatory: false,
            errorMessages: {
                validation: 'error.missing.companyCity',
                range: 'error.range.companyCity',
                regex: 'error.validation.companyCity'
            },
            mandatoryIf: {
                key: 'isB2B',
                value: [true]
            }
        },
        companyPostCode: {
            regex: FieldValidationRules.POSTAL.REGEX,
            minLength: FieldValidationRules.POSTAL.MIN,
            maxLength: FieldValidationRules.POSTAL.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.companyPostCode',
                range: 'error.range.companyPostCode',
                regex: 'error.validation.companyPostCode'
            },
            mandatoryIf: {
                key: 'isB2B',
                value: [true]
            }
        },
        region: {
            mandatory: false,
            maxLength: 10,
            mandatoryIf: {
                key: 'companyCountry',
                value: ['US', 'CA']
            },
            errorMessages: {
                validation: 'error.missing.state'
            }
        },
        vatId: {
            maxLength: 20,
            mandatory: false,
            regex: /^\d*$/,
            errorMessages: {
                range: 'error.range.vat.id',
                regex: 'error.validation.vat.id'
            }
        }
    } as const;

    return validationAccountMapping;
};

const handleValidationAccountEditMapping = () => {
    const validationAccountEditMapping: ValidationMapping = {
        firstName: {
            regex: FieldValidationRules.NAME.REGEX,
            minLength: FieldValidationRules.NAME.MIN,
            maxLength: FieldValidationRules.NAME.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.first.name',
                range: 'error.range.first.name',
                regex: 'error.validation.name'
            }
        },
        lastName: {
            regex: FieldValidationRules.NAME.REGEX,
            minLength: FieldValidationRules.NAME.MIN,
            maxLength: FieldValidationRules.NAME.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.last.name',
                range: 'error.range.last.name',
                regex: 'error.validation.name'
            }
        },

        jobTitle: {
            regex: validationRules.NAME, //todo
            maxLength: 50,
            mandatory: false,
            errorMessages: {
                validation: 'error.missing.job.title',
                range: 'error.range.job.title',
                regex: 'error.validation.name'
            },
            mandatoryIf: {
                key: 'isB2B',
                value: [true]
            }
        },
        phoneMobile: {
            regex: FieldValidationRules.PHONE.REGEX,
            minLength: FieldValidationRules.PHONE.MIN,
            maxLength: FieldValidationRules.PHONE.MAX,
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.phoneNumber',
                range: 'error.missing.phoneNumber'
            }
        },
        salutation: {
            mandatory: true,
            errorMessages: {
                validation: 'error.missing.salutation'
            }
        }
    } as const;

    return validationAccountEditMapping;
};

const validationPassword: ValidationMapping = {
    oldPassword: {
        mandatory: true,
        errorMessages: {
            validation: 'error.missing.password'
        }
    },

    newPassword: {
        mandatory: true,
        errorMessages: {
            validation: 'error.missing.password'
        }
    },
    confirmPassword: {
        mandatory: true,
        errorMessages: {
            validation: 'error.confirm.password'
        },
        sameAs: 'newPassword'
    }
} as const;

const validationDelegatesData: ValidationMapping = {
    firstName: {
        regex: FieldValidationRules.NAME.REGEX,
        minLength: FieldValidationRules.NAME.MIN,
        maxLength: FieldValidationRules.NAME.MAX,
        mandatory: true,
        errorMessages: {
            validation: 'error.missing.first.name',
            range: 'error.range.first.name',
            regex: 'error.validation.name'
        }
    },
    lastName: {
        regex: FieldValidationRules.NAME.REGEX,
        minLength: FieldValidationRules.NAME.MIN,
        maxLength: FieldValidationRules.NAME.MAX,
        mandatory: true,
        errorMessages: {
            validation: 'error.missing.last.name',
            range: 'error.range.last.name',
            regex: 'error.validation.name'
        }
    },
    email: {
        regex: FieldValidationRules.EMAIL.REGEX,
        minLength: FieldValidationRules.EMAIL.MIN,
        maxLength: FieldValidationRules.EMAIL.MAX,
        mandatory: true,
        errorMessages: {
            validation: 'error.missing.email',
            range: 'error.range.email',
            regex: 'error.validation.email'
        }
    }
} as const;

const isMandatoryFieldInvalid = (value: string | undefined, fieldValidation: Record<string, any>) => {
    return isEmpty(value) && fieldValidation.mandatory && !fieldValidation.mandatoryIf;
};

const isMinLenghtInvalid = (value: string, fieldValidation: Record<string, any>) =>
    value?.length > 0 && value?.length < (fieldValidation?.minLength ?? 0);

const isMaxLenghtInvalid = (value: string, fieldValidation: Record<string, any>) =>
    value?.length > (fieldValidation?.maxLength ?? Infinity);

const isRegexInvalid = (value: string, fieldValidation: Record<string, any>) =>
    isNotEmpty(value) && fieldValidation.regex && !fieldValidation.regex.test(value);

const isSameAsInvalid = (value: string, data: any, fieldValidation: Record<string, any>) =>
    fieldValidation?.sameAs && value !== data[fieldValidation?.sameAs as keyof typeof data];

const isMandatoryIf = (data: any, fieldValidation: any) =>
    fieldValidation.mandatoryIf?.key &&
    fieldValidation.mandatoryIf?.value?.includes(data[fieldValidation.mandatoryIf.key as keyof typeof data]);

const isMandatoryIfInvalid = (value: string | undefined, data: any, fieldValidation: any) =>
    isEmpty(value) && isMandatoryIf(data, fieldValidation);

const validateField = (
    name: string,
    validationMapping: ValidationMapping,
    data: Address | AccountExtended | AccountChangePassword,
    errors: ValidationErrors
): ValidationErrors | null => {
    const value = data[name as keyof typeof data];
    const errorField = name as keyof typeof errors;
    const isStringValue = typeof value === 'string';
    const fieldValidation = validationMapping[name];

    if (!fieldValidation) {
        return null;
    }

    if (isMandatoryFieldInvalid(value, fieldValidation)) {
        errors[errorField] = { message: fieldValidation.errorMessages.validation };
    } else if (isStringValue) {
        if (isMinLenghtInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.range };
        } else if (isMaxLenghtInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.range };
        } else if (isRegexInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.regex };
        } else if (isSameAsInvalid(value, data, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.validation };
        } else if (isMandatoryIfInvalid(value, data, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.validation };
        }
    }

    return Object.keys(errors).length > 0 ? errors : null;
};

const validate = (
    data: Address | AccountExtended | AccountChangePassword,
    validationMapping: ValidationMapping,
    errors: ChangePasswordFieldErrors | FieldErrors | AccountFieldErrors
): ChangePasswordFieldErrors | FieldErrors | AccountFieldErrors | null => {
    for (const field of Object.keys(validationMapping)) {
        const fieldValidation = validationMapping[field];

        if (!fieldValidation) {
            continue;
        }

        const value = data[field as keyof typeof data];
        const errorField = field as keyof typeof errors;

        const isStringValue = typeof value === 'string';

        if (isMandatoryFieldInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.validation };
        } else if (isStringValue && isMinLenghtInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.range };
        } else if (isStringValue && isMaxLenghtInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.range };
        } else if (isStringValue && isRegexInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.regex };
        } else if (isStringValue && isSameAsInvalid(value, data, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.validation };
        }

        if (isMandatoryIfInvalid(value, data, fieldValidation) && !isStringValue) {
            errors[errorField] = { message: fieldValidation.errorMessages.validation };
        }
    }

    return Object.keys(errors).length > 0 ? errors : null;
};

const getFieldValidation = (field: string) => {
    if (field.startsWith('firstName')) return validationDelegatesData.firstName;
    if (field.startsWith('lastName')) return validationDelegatesData.lastName;
    return validationDelegatesData.email;
};

export const validateDelegate = (data: DelegatesData): DelegatesFieldErrors | null => {
    const errors: DelegatesFieldErrors = {};

    for (const field of Object.keys(data)) {
        const fieldValidation = getFieldValidation(field);

        if (!fieldValidation) {
            continue;
        }

        const value = data[field];
        const errorField = field;

        const isStringValue = typeof value === 'string';

        if (isMandatoryFieldInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.validation };
        } else if (isStringValue && isMinLenghtInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.range };
        } else if (isStringValue && isMaxLenghtInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.range };
        } else if (isStringValue && isRegexInvalid(value, fieldValidation)) {
            errors[errorField] = { message: fieldValidation.errorMessages.regex };
        }
    }

    return Object.keys(errors).length > 0 ? errors : null;
};

export const validateAddressField = (address: Address, name: string) => {
    const errors: FieldErrors = {};
    const validationMapping = handleValidationMapping();
    return validateField(name, validationMapping, address, errors);
};

export const validateFields = (address: Address) => {
    const errors: FieldErrors = {};
    const validationMapping = handleValidationMapping();
    return validate(address, validationMapping, errors);
};

export const validateAccountFields = (account: AccountExtended) => {
    const errors: AccountFieldErrors = {};
    const validationAccountEditMapping = handleValidationAccountEditMapping();
    const validationAccountMapping = handleValidationAccountMapping();

    const validationRegistrationMapping = { ...validationAccountEditMapping, ...validationAccountMapping };
    return validate(account, validationRegistrationMapping, errors);
};

export const validatePasswordFields = (passwords: AccountChangePassword) => {
    const errors: ChangePasswordFieldErrors = {};
    return validate(passwords, validationPassword, errors);
};

export const validateDelegateFields = (delegatesData: any) => {
    const errors: ChangePasswordFieldErrors = {};

    return validate(delegatesData, validationDelegatesData, errors);
};

export const validateAccountEditFields = (account: AccountExtended) => {
    const errors: AccountFieldErrors = {};
    const validationAccountEditMapping = handleValidationAccountEditMapping();
    return validate(account, validationAccountEditMapping, errors);
};

export const validateReCaptcha = async (reCaptchaToken: string | undefined, reCaptchaSettings: ReCaptchaBackendSettings) => {
    const errors: ReCaptchaErrors = {};
    if (!reCaptchaSettings.reCaptchaEnabled) {
        return null;
    }

    let reCaptchaMinScore = Number(reCaptchaSettings.reCaptchaMinScore);
    if (!reCaptchaSettings.reCaptchaMinScore || reCaptchaMinScore < 0 || reCaptchaMinScore > 1) {
        reCaptchaMinScore = 0.5;
        console.warn('<--- reCAPTCHAMinScore not set or not in range of in project config, fallback to default value of 0.5');
    }

    console.log('---> reCaptchaToken', reCaptchaToken);

    if (reCaptchaToken) {
        try {
            console.log('---> try');

            const res = await fetch(reCaptchaSettings.reCaptchaVerificationUrl || 'https://www.google.com/recaptcha/api/siteverify', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
                },
                body: `secret=${reCaptchaSettings.reCaptchaSecretKey}&response=${reCaptchaToken}`
            });

            await validateReCaptchaResponse(res, reCaptchaMinScore, errors);
        } catch {
            console.log('---> catch');

            console.error('<--- reCAPTCHA could not fetch');
        }
    } else {
        errors.reCaptchaToken = {
            showError: true,
            message: 'error.missing.reCaptchaToken'
        };
        console.warn('<--- reCAPTCHA missing token');
    }

    return Object.keys(errors).length > 0 ? errors : null;
};

const validateReCaptchaResponse = async (res: Response, reCaptchaMinScore: number, errors: ReCaptchaErrors) => {
    console.log('---> validateReCaptchaResponse');

    if (!res?.ok || !res?.body) {
        errors.reCaptchaToken = {
            showError: true,
            message: 'error.invalid.reCaptchaToken'
        };
        console.warn('<--- reCAPTCHA response not ok or no body, check reCaptcha verification url');
        console.info('<--- reCAPTCHA response', res);
        return errors;
    }

    const reCaptchaVerifyResponse = (await res.json()) as ReCaptchaVerifyResponse;

    console.log('---> reCaptchaVerifyResponse', reCaptchaVerifyResponse);

    if (!reCaptchaVerifyResponse.success) {
        console.log('---> !reCaptchaVerifyResponse.success', !reCaptchaVerifyResponse.success);

        errors.reCaptchaToken = {
            showError: true,
            message: 'error.invalid.reCaptchaToken'
        };
        if (reCaptchaVerifyResponse['error-codes']?.includes('timeout-or-duplicate')) {
            console.info('<--- reCAPTCHA response not successful', reCaptchaVerifyResponse);
        } else {
            console.warn('<--- reCAPTCHA response not successful', reCaptchaVerifyResponse);
        }
    } else if (reCaptchaVerifyResponse.score < reCaptchaMinScore) {
        errors.reCaptchaToken = {
            showError: true,
            message: 'error.invalid.reCaptchaToken'
        };
        console.info(`<--- reCAPTCHA score too low, score: ${reCaptchaVerifyResponse.score}, needed score: ${reCaptchaMinScore}`);
    }
    console.log('---> errors', errors);

    return errors;
};
